import React from 'react'
import Leftgrape from "../../assets/Image/grapes 2.png";
import Rightgrape from "../../assets/Image/grapes 1.png";
import Nikmat from "../../assets/Image/73.png";
import Halus from "../../assets/Image/72.png";

const Grapes = () => {
  return (
    <div className='w-full h-full absolute overflow-hidden'>
        <img src={Leftgrape} className='w-56 sm:w-[30vw] absolute -left-28 top-24' alt="" />
        <img src={Rightgrape} className='w-56 sm:w-[30vw] absolute -right-28 top-24' alt="" />

        <img src={Nikmat} className='w-32 sm:w-72 absolute left-3 sm:left-28 top-40 sm:top-52' alt="" />
        <img src={Halus} className='w-32 sm:w-72 absolute right-3 sm:right-28 top-52 sm:top-72' alt="" />
        
    </div>
  )
}

export default Grapes