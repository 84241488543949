import { useNavigate, Link } from "react-router-dom";
import Bgimage from "../../assets/Image/wine field image.png";
import Qrologo from "../../assets/Image/logo navbar.png";
import Woodcircle from "../../assets/Image/wood button 2.png";
import { useState } from "react"; // Make sure to import useState

const Verification = () => {
  const navigate = useNavigate();

  // State to manage the input values
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  // Function to handle input and ensure only numbers are entered
  const handleInput = (e) => {
    const { maxLength, value, name } = e.target;
    const [fieldName, fieldIndex] = name.split("-");

    // Check if the input is numeric
    if (!/^\d*$/.test(value)) {
      alert("Silakan masukkan angka saja.");
      e.target.value = value.slice(0, -1); // Remove the last non-numeric character
      return; // Exit the function early
    }

    // Move to next field if max length is reached
    if (value.length >= maxLength) {
      const nextSibling = document.querySelector(
        `input[name=${fieldName}-${parseInt(fieldIndex, 10) + 1}]`
      );

      if (nextSibling !== null) {
        nextSibling.focus();
      }
    }
  };

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Calculate age
    let birthDate = new Date(year, month - 1, day); // JavaScript months are 0-indexed
    let today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    // Check if user is at least 21 years old
    if(day == "" || month == "" || year == ""){
      alert("Tolong isi semua input yang diperlukan");
    }
    else if (age < 21) {
      alert("Anda harus berusia minimal 21 tahun.");
    } else {
      navigate("/home"); // Redirects to the home page if age check passes
    }
  };

  return (
    <div className='w-screen h-screen bgverification flex justify-center items-center z-50 overflow-hidden px-10 py-14'>
      <div className='flex flex-col bg-white justify-center items-center  px-10 py-16 relative rounded-2xl shadowbox2'>
        <img src={Qrologo} className='absolute -top-9 w-20' alt='' />
        <img src={Woodcircle} className='absolute w-10 top-3 left-3' alt='' />
        <img src={Woodcircle} className='absolute w-10 top-3 right-3' alt='' />

        <h1 className='lora text-center'>
          Selamat datang di website resmi{" "}
          <span className='font-bold'>Q’RO</span>!
        </h1>
        <h2 className='publicsans text-center italic text-sm font-thin'>
          Mohon isi tanggal lahir kalian
        </h2>
        <br />
        {/* Age input form */}
        <form
          onSubmit={handleSubmit}
          className='flex flex-col gap-4 lora w-full px-10'>
          <div className='flex flex-col text-xs opacity-70 gap-1 w-full'>
            <label htmlFor=''>Tanggal</label>
            <input
              type='text'
              placeholder='DD'
              name='date-1'
              maxLength={2}
              value={day}
              onInput={handleInput}
              onChange={(e) => setDay(e.target.value)}
              className='form-input focus:outline-none border-[1px] border-[#3b6446] p-2 rounded'
            />
          </div>
          <div className='flex flex-col text-xs opacity-70 gap-1 '>
            <label htmlFor=''>Bulan</label>
            <input
              type='text'
              placeholder='MM'
              name='date-2'
              maxLength={2}
              value={month}
              onInput={handleInput}
              onChange={(e) => setMonth(e.target.value)}
              className='form-input focus:outline-none border-[1px] border-[#3b6446] p-2 rounded'
            />
          </div>

          <div className='flex flex-col text-xs opacity-70 gap-1 '>
            <label htmlFor=''>Tahun</label>
            <input
              type='text'
              placeholder='YYYY'
              name='date-3'
              maxLength={4}
              value={year}
              onChange={(e) => setYear(e.target.value)}
              className='form-input focus:outline-none border-[1px] border-[#3b6446] p-2 rounded'
            />
          </div>

          {/* <button type='submit' className='submit-button'>
            Submit
          </button> */}
          <button
            onClick={handleSubmit}
            type='submit'
            className='submit-button lora bg-[#2a7d2e] mx-10 p-2 rounded-lg text-white shadowbox2 active:shadow-none hover:bg-[#1d6121]'>
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Verification;
