import './styles/App.css';
import './styles/font.css';
import './styles/home.css';
import './styles/about.css';
import './styles/verification.css';
import Navbar from './components/Navbar';
import Home from './components/Home/Home';
import Compare from './components/Compare/Compare';
import About from './components/About/About';
import Product from './components/Product/Product';
import Sosmed from './components/Sosmed/Sosmed';
import Footer from './components/Footer/Footer';
import Verification from './components/Verification/Verification';

function App() {
  return (
    <div className=" h-full w-full overflow-x-hidden flex flex-col">
      {/* <Verification/> */}

      <Navbar/>
      <Home/>
      <Compare/>
      <About/>
      <Product/>
      <Sosmed />
      <Footer />

    </div>
  );
}

export default App;
