import React from 'react'
import Title from './Title'
import Leafs from './Leafs'
import Content from './Content'

const product = () => {
  return (
    <div id='product' className='w-full h-full relative'>
        <Title />
        <Leafs />
        <Content />

    </div>
  )
}

export default product