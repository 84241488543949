import BotolQro from "../../assets/Image/botol qro.png";
import BotolQuro from "../../assets/Image/botol quro 2.png";
import {
  useMotionValueEvent,
  motion,
  useScroll,
  useTransform,
} from "framer-motion";

const Botols = () => {
  let { scrollYProgress } = useScroll();
  let y = useTransform(scrollYProgress, [0, 1], ["0px", "150px"]);
  let x = useTransform(scrollYProgress, [0, 1], ["0px", "-150px"]);
  let rotate = useTransform(scrollYProgress, [0, 1], ["0deg", "360deg"]);

  return (
    <div className='flex flex-row gap-4 sm:gap-10'>
     
      <motion.img
        src={BotolQro}
        className='w-14 sm:w-32 object-contain'
        alt=''
      />
      <motion.img
        src={BotolQro}
        className='w-14 sm:w-32 object-contain'
        alt=''
      />
      <motion.img
        src={BotolQro}
        className='w-14 sm:w-32 object-contain hidden sm:block'
        alt=''
      />

    </div>
  );
};

export default Botols;
