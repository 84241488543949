import React from "react";
import Qrologo from "../assets/Image/logo navbar.png"
import Qrologotext from "../assets/Image/Q'RO font logo transparent.png"
import Menuicon from "../assets/Image/menu-bar.png"

const navbar = () => {
  return (
    <nav className='fixed z-40 border-gray-200 h-[8vh] px-10 w-full bg-[#3b6446] rounded-b-3xl sm:px-[5vw]'>
      <div className='relative max-w-screen-xl flex flex-wrap items-center h-full justify-between mx-auto '>
        <a
          href=''
          className='flex sm:top-[20%] absolute items-center space-x-3  top-3 left-1/2 sm:left-0 transform -translate-x-1/2 rtl:space-x-reverse'>
          <img
            src={Qrologo}
            className='h-[14vh] sm:h-[16vh] rounded-full'
            alt='Flowbite Logo'
          />
          
        </a>
        <div>

        </div>
        {/* <div>
          <img src={Menuicon} className="sm:hidden h-4" alt="" />
        </div> */}
        <div className='hidden w-full sm:block md:w-auto' id='navbar-default'>
          <ul className='font-medium flex flex-col p- md:p-0 mt-4 border border-gray-100 rounded-lg md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0  dark:border-gray-700'>
            <li>
              <a
                href='#home'
                className='block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0  md:p-0 dark:text-white hover:text-[#f1b497]  md:dark:hover:bg-transparent '
                aria-current='page'>
                Home
              </a>
            </li>
            <li>
              <a
                href='#about'
                className='block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0  md:p-0 dark:text-white hover:text-[#f1b497]  md:dark:hover:bg-transparent'>
                About us
              </a>
            </li>
            <li>
              <a
                href='#product'
                className='block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0  md:p-0 dark:text-white hover:text-[#f1b497]  md:dark:hover:bg-transparent'>
                Products
              </a>
            </li>
            <li>
              <a
                href='#contact'
                className='block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0  md:p-0 dark:text-white hover:text-[#f1b497]  md:dark:hover:bg-transparent'>
                Contact
              </a>
            </li>
            
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default navbar;
