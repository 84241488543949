import Title from "./Title";
import Table from "./Table";
import Textslider from "./Textslider";
import Desktop from "./Desktop";
import Mobile from "./Mobile";

const About = () => {
  return (
    <div id="about" className='aboutbg relative bg-contain h-full mt-10 bg-no-repeat w-screen '>
      <Desktop />
      <Title />
      <Table />
      <Textslider />
      <Mobile />
    </div>
  );
};

export default About;
