import Tablewine from "../../assets/Image/table wine glass and qro.png"

const Table = () => {
  return (
    <div>
        <img className="z-10 relative pt-24 sm:pt-96 px-4 sm:px-[13vw]" src={Tablewine} alt="" />
    </div>
  )
}

export default Table