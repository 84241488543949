import Qrologo from "../../assets/Image/logo navbar.png"

const Footer = () => {
  return (
    <div id="footer" className='bg-[#3B6346] w-full h-14 sm:h-24 flex justify-between px-3 sm:px-20 items-center'>
        <img src={Qrologo} className="w-6 sm:w-16 object-contain" alt="" />
        <h1 className="text-xs sm:text-lg text-white">© 2024 Q'RO. All Rights Reserved.</h1>
    </div>
  )
}

export default Footer