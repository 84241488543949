import title from "../../assets/Image/Group 2.png";
import Botols from "./Botols";
import Comparetitle from "./Comparetitle";
import Grapes from "./Grapes";
import Label from "../../assets/Image/74.png";

const Compare = () => {
  return (
    <div id='compare' className='h-full sm:h-[100vh] w-full sm:mt-[25vw] relative flex flex-col justify-top items-center gap-14 overflow-hidden'>
      <Comparetitle/>
      <Grapes />
      <div className="flex flex-col h-full justify-start items-center gap-5 pb-5">
        <Botols />
        {/* <img src={Label} className='w-52 sm:w-96   ' alt="" /> */}
      </div>
    </div>
  );
};

export default Compare;
