import Titletext from "../../assets/Image/Group 3.png";

const Title = () => {
  return (
    <div className="w-full h-full relative sm:hidden justify-center">
        <img src={Titletext} className="absolute -top-16 w-full sm:-top-24 sm:w-[44vw]" alt="" />
    </div>
  )
}

export default Title