import BotolQro from "../../assets/Image/botol qro.png";
import BotolQroRight from "../../assets/Image/qro avici.png";
import BotolQroLeft from "../../assets/Image/Botol qro kanan.png";
import {
  useMotionValueEvent,
  motion,
  useScroll,
  useTransform,
} from "framer-motion";

const Botols = () => {
  let { scrollYProgress } = useScroll();
  let y = useTransform(scrollYProgress, [0, 0.2], ["0px", "150px"]);
  let x = useTransform(scrollYProgress, [0, 1], ["0px", "-150px"]);
  let rotate = useTransform(scrollYProgress, [0, 1], ["0deg", "360deg"]);

  return (
    <div className='w-full h-full absolute '>
      <motion.img
        style={{ y }}
        src={BotolQroLeft}
        className='ml-2 sm:ml-[15vw] mt-[25vh] sm:mt-[15vh] absolute left-0 w-40 sm:w-[21vw]'
        alt=''
      />
      <motion.img
        style={{ y }}
        src={BotolQroRight}
        className='z-0 ml-2 sm:mr-[15vw] mt-[25vh] sm:mt-[16vh] absolute right-0 w-40  sm:w-[21vw]'
        alt=''
      />
    </div>
  );
};

export default Botols;
