import React from "react";
import Icons from "./Icons";
import Bgsosmed from "../../assets/Image/wine field circle background 2 (1).png";

const Sosmed = () => {
  return (
    <div id="contact" className='flex justify-start h-[350px] sm:h-screen flex-col items-center gap-14 sm:gap-20 relative sm:mt-20 overflow-hidden'>
      <div className="sm:flex sm:flex-col sm:gap-3">
        <h1 className='lora text-center text-lg sm:text-5xl tracking-tight'>
          Temukan <span className='font-bold'>Q’RO</span> di Tokopedia dan
        </h1>
        <h1 className='lora text-center text-lg sm:text-5xl tracking-tight'>
          Follow kami di Instagram & TikTok!
        </h1>
      </div>

      <Icons />
      <img
        src={Bgsosmed}
        className='absolute bottom-0 sm:-bottom-[420px] -z-10 sm:w-screen  object-cover'
        alt=''
      />
    </div>
  );
};

export default Sosmed;
