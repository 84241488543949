import Tokped from "../../assets/Image/tokped icon.png";
import Whatsapp from "../../assets/Image/wa icon.png";
import Instagram from "../../assets/Image/ig icon.png";
import Tiktok from "../../assets/Image/tt icon.png";

const Icons = () => {
  return (
    <div className='w-[60vw] sm:w-[40vw] h-16 sm:h-36 bg-white rounded-3xl flex justify-around sm:justify-between items-center shadowbox2 sm:px-10'>
      <a href="https://www.tokopedia.com/seven24o/seven24-qrh-q-uro-anggur-hijau-650ml">
        <img src={Tokped} className='w-8 sm:w-20' alt='' />

      </a>
      <a href='https://wa.me/message/P4TQZF4MY6LYL1'>
        <img src={Whatsapp} className='w-8 sm:w-20' alt='' />
      </a>
      <a href='https://www.instagram.com/qro.official/'>
        <img src={Instagram} className='w-8 sm:w-20' alt='' />
      </a>

      <a href='https://www.tiktok.com/@qro.official?is_from_webapp=1&sender_device=pc'>
        <img src={Tiktok} className='w-8 sm:w-20' alt='' />
      </a>
    </div>
  );
};

export default Icons;
