import Titletext from "../../assets/Image/Group 4.png";

const Title = () => {
  return (
    <div className='w-full relative sm:hidden justify-center my-10'>
      <img
        src={Titletext}
        className=' w-full sm:w-[44vw]'
        alt=''
      />
    </div>
  );
};

export default Title;
