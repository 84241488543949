import Leaf1 from "../../assets/Image/tropical leaf 1.png";
import Leaf2 from "../../assets/Image/tropical leaf 2.png";
import {
    useMotionValueEvent,
    motion,
    useScroll,
    useTransform,
  } from "framer-motion";

const Leafs = () => {
  let { scrollYProgress } = useScroll();
//   let y = useTransform(scrollYProgress, [0, 1], ["0px", "150px"]);
//   let x = useTransform(scrollYProgress, [0, 1], ["0px", "-150px"]);
let rotate1 = useTransform(scrollYProgress, [0.7, 1], ["0deg", "-75deg"]);
let rotate2 = useTransform(scrollYProgress, [0.7, 1], ["0deg", "75deg"]);

  return (
    <div className='w-full h-auto absolute '>
      <motion.img
        style={{ rotate: rotate1, transformOrigin: "top left" }}
        src={Leaf1}
        className=' sm:ml-[15vw] sm:mt-[15vh] absolute -top-0 -z-10 -left-20 sm:-left-72 w-48 sm:w-[25vw]'
        alt=''
      />
      <motion.img
        style={{ rotate: rotate2, transformOrigin: "top right" }}
        src={Leaf2}
        className=' sm:mr-[15vw] sm:mt-[15vh] absolute -top-0 -z-10 -right-20 sm:-right-72 w-48  sm:w-[26vw]'
        alt=''
      />
    </div>
  );
};

export default Leafs;
