import React from "react";

const Mobile = () => {
  return (
    <div>
      <div className="sm:hidden">
        <h1 className="mx-4 py-5 px-6 text-justify publicsans tracking-tighter shadow-xl border-4 containertextshadow rounded-3xl bg-white">
          Q'RO, anggur burung hantu autentik dari Indonesia. Dibuat dari anggur
          hijau pilihan dan proses fermentasi terbaik, menghasilkan perpaduan
          cita rasa manis dan halus dengan sentuhan alkohol premium.
          <br />
          <br />
          Q’RO mau diminum langsung dari botol pun gabakal eneg, soalnya emang
          se-manis & se-halus itu!
          <br />
          <br />
          ciptakan sensasi minum yang tak terlupakan dan lengkapi momen-momen
          spesialmu bersama Q'RO Anggur Hijau!
        </h1>
      </div>
    </div>
  );
};

export default Mobile;
