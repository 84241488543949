import Wing1 from "../../assets/Image/owl wing 2.png";
import Wing2 from "../../assets/Image/owl wing 1.png";
import {
  useMotionValueEvent,
  motion,
  useScroll,
  useTransform,
} from "framer-motion";

const Botols = () => {
  let { scrollYProgress } = useScroll();
  let y = useTransform(scrollYProgress, [0, 0.2], ["0px", "550px"]);
  let x = useTransform(scrollYProgress, [0, 0.2], ["0px", "-150px"]);
  let rotate1 = useTransform(scrollYProgress, [0, 0.2], ["0deg", "75deg"]);
  let rotate2 = useTransform(scrollYProgress, [0, 0.2], ["0deg", "-75deg"]);

  return (
    <div className='w-full h-[100%] sm:h-[200%] absolute overflow-hidden'>
      <motion.img
        style={{rotate: rotate1}}
        src={Wing1}
        className='absolute -left-16 sm:-left-[8vw] bottom-5 sm:bottom-[40vh] w-64 sm:w-[30vw]'
        alt=''
      />
      <motion.img
        style={{rotate: rotate2}}
        src={Wing2}
        className='absolute -right-16 sm:-right-[8vw] bottom-5 sm:bottom-[40vh] w-64 sm:w-[30vw]'
        alt=''
      />
    </div>
  );
};

export default Botols;
