import React from "react";

const Desktop = () => {
  return (
    <div className='w-full h-full'>
      <div className='absolute hidden sm:block z-10 sm:mt-64 sm:mx-20 sm:text-[7vw] lora '>
        <h1 className='leading-none'>
          Tentang
          <br />
          <span className='font-bold tracking-tighter	'>Q’RO;</span>
        </h1>
      </div>
      <div className='absolute hidden sm:block z-10 sm:mt-72 right-0 sm:mx-20 sm:text-2xl lora '>
        <h1 className='leading-none w-[29vw] publicsans text-justify '>
          Q'RO, anggur burung hantu autentik dari Indonesia. Dibuat dari anggur
          hijau pilihan dan proses fermentasi terbaik, menghasilkan perpaduan
          cita rasa manis dan halus dengan sentuhan alkohol premium.  
          <br />
          <br />
          Q’RO mau diminum langsung dari botol pun gabakal eneg, soalnya emang
          se-nikmat & se-halus itu!
          <br />
          <br />
          ciptakan sensasi minum yang tak terlupakan dan lengkapi momen-momen
          spesialmu bersama Q'RO Anggur Hijau!
        </h1>
      </div>
    </div>
  );
};

export default Desktop;
