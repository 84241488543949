import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/autoplay";
import "swiper/css";

import { Autoplay } from "swiper/modules";

const Textslider = () => {
  return (
    <div className='absolute top-20 sm:top-1/4 w-[650px] sm:w-[230vw] flex'>
      <Swiper
        className=' w-[1800px] swiper-wrapper h-full relative flex flex-row gap-96 '
        loop={true}
        modules={[Autoplay]}
        speed={17000}
        autoplay={{
          delay: 0,
          disableOnInteraction: false,
        }}
        slidesPerView={1}
        >
        <SwiperSlide>
          <div className="w-[600px]   ">
            <h1 className='yellowtail w-full text-center inline-block text-8xl sm:text-[35vw] whitespace-nowrap text-white'>
              Halus itu Nikmat
            </h1>
          </div>
        </SwiperSlide>
       
        <SwiperSlide>
          <div className="w-[600px]">
            <h1 className='yellowtail w-full text-center  inline-block text-8xl sm:text-[35vw] whitespace-nowrap text-white'>
              Halus itu Nikmat
            </h1>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="w-[600px]  ">
            <h1 className='yellowtail w-full text-center  inline-block text-8xl sm:text-[35vw] whitespace-nowrap text-white'>
              Halus itu Nikmat
            </h1>
          </div>
        </SwiperSlide>
        
      </Swiper>
    </div>
  );
};

export default Textslider;
